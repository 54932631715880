import logo from './../image/logo.svg';
import energia from './../image/energia.svg';
import biodiversita from './../image/biodiversita.svg';
import rifiuti from './../image/rifiuti.svg';
import ecosostenibilita from './../image/ecosostenibilita.svg';
import mobilita from './../image/mobilita.svg';

const getLogo = () => {
	const category = localStorage.getItem('category');
	let result = null;
	switch (category) {
		case '1':
			result = rifiuti;
			break;
		case '2':
			result = ecosostenibilita;
			break;
		case '3':
			result = energia;
			break;
		case '4':
			result = mobilita;
			break;
		case '5':
			result = biodiversita;
			break;
		default:
			result = logo;
			break;
	}
	return result;
};

export default getLogo;
