const getCategory = () => {
	const category = localStorage.getItem('category');
	let result = '';
	switch (category) {
		case '1':
			result = 'rifiuti';
			break;
		case '2':
			result = 'ecosostenibilita';
			break;
		case '3':
			result = 'energia';
			break;
		case '4':
			result = 'mobilita';
			break;
		case '5':
			result = 'biodiversita';
			break;
		default:
			result = 'general';
			break;
	}
	return result;
};

export default getCategory;
